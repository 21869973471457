<template>
  <div class="container test-page">
    <div class="question">
      <div class="title">
				<h2>
					<span>{{ tpTxt }}</span>
				</h2>
				<el-divider></el-divider>
				<p v-if="false">学生姓名：{{ evaRes.name }}</p>
				<p v-if="false">学籍号：{{ evaRes.name }}</p>
				<p>测评名称：{{ evaRes.name }}</p>
				<p>学期：{{ evaRes.term }}</p>
				<template v-if="tp == 400">
					<p>测评结果：{{ evaRes.evaScore }}分</p>
					<p>测评等级：{{ evaRes.evaResult }}</p>
					<p>建议：{{ evaRes.evaIdea }}</p>
				</template>
				<template v-else>
					<p>测评类别：
						<span>{{ tpTxt }}</span>
					</p>
					<p>测评成绩：{{ evaRes.evaScore }}分</p>
					<p>综素成绩：{{ evaRes.indexScore }}分</p>
				</template>
      </div>
	  <!-- 心理展示分维度的评估结果 -->
	  <div class="naire" v-if="tp == 400">
		<p>测评结果详情：</p>
		<template v-for="(item, idx) in evaRes.evaResultNorm">
			<h4>{{ item.dimensionManagerName }}</h4>
			<table border="1" cellspacing="0">
			  <tr>
			    <th>测评维度</th>
			    <th>得分</th>
			    <!-- <th>测评结果</th>
			    <th>建议</th> -->
			  </tr>
			  <tr>
			    <td>{{ item.dimensionManagerName }}</td>
			    <td>{{ item.dimensionScore }}</td>
			    <!-- <td>{{ item.dimensionResult }}</td>
			    <td>{{ item.dimensionIdea }}</td> -->
			  </tr>
			</table>
			<psy-score-chart desc="量表：" desc-width="50px" :score="item.dimensionScore" :score-model="item._scoreModel"></psy-score-chart>
		</template>
	  </div>
	  <!-- 健康与艺术直接展示题目 -->
      <div class="naire" v-else>
		<el-divider v-if="topicList.singleAnswer.length > 0">单选题 <span v-if="tp != 400">(共{{ totalScore.singleAnswer }}分)</span> </el-divider>
		<el-form>
			<el-form-item v-for="(item, idx) in topicList.singleAnswer" :key="idx">
				<h4>{{ idx + 1 }}. <span v-html="item.topic"></span> <span v-if="tp != 400">({{ item.topicScore }}分)</span> </h4>
				<div class="topic-media" v-if="tp == 402">
					<media-exhi v-if="item.imgUrl" :fileData="item.imgUrl"></media-exhi>
				</div>
				<div class="options-list">
					<div class="options-item" v-for="(item1, idx1) in item.topicDetails">
						<div class="left-side">
							<div class="sel">
								<div class="circle-icon" :class="{ 'selected': item.answer == item1.topicOptions }">
									<i class="el-icon-check"></i>
								</div>
							</div>
						</div>
						<div class="right-side">
							<div class="opt-cont" v-html="item1.topicOptionsContent"></div>
							<media-exhi v-if="tp == 402 && item1.imgUrl" :fileData="item1.imgUrl" size="50px"></media-exhi>
						</div>
					</div>
				</div>
				<p>我的答案：{{ item.answer }}，正确答案：<span class="right-answer">{{ item.topicAnswer }}</span></p>
			</el-form-item>
		</el-form>
		<el-divider v-if="topicList.multiAnswer.length > 0">多选题 <span v-if="tp != 400">(共{{ totalScore.multiAnswer }}分)</span></el-divider>
		<el-form>
			<el-form-item v-for="(item, idx) in topicList.multiAnswer" :key="idx">
				<h4>{{ idx + 1 }}. <span v-html="item.topic"></span> <span v-if="tp != 400">({{ item.topicScore }}分)</span> </h4>
				<div class="topic-media" v-if="tp == 402">
					<media-exhi v-if="item.imgUrl" :fileData="item.imgUrl"></media-exhi>
				</div>
				<div class="options-list">
					<div class="options-item" v-for="(item1, idx1) in item.topicDetails">
						<div class="left-side">
							<div class="sel">
								<div class="circle-icon" :class="{ 'selected': item._answer.indexOf(item1.topicOptions) != -1 }">
									<i class="el-icon-check"></i>
								</div>
							</div>
						</div>
						<div class="right-side">
							<div class="opt-cont" v-html="item1.topicOptionsContent"></div>
							<media-exhi v-if="tp == 402 && item1.imgUrl" :fileData="item1.imgUrl" size="50px"></media-exhi>
						</div>
					</div>
				</div>
				<p>我的答案：{{ item.answer }}，正确答案：<span class="right-answer">{{ item.topicAnswer }}</span></p>
			</el-form-item>
		</el-form>
      </div>
    </div>
    <div class="btn-box">
      <el-button @click="backIndex">关 闭</el-button>
    </div>
    <div class="footer">
      <div class="footer-content">
        <div class="footer-center">
          <p>版权所有：秦皇岛市教育局</p>
          <p>地址：河北省秦皇岛市海港区秦皇东大街485号</p>
        </div>
        <div class="footer-center">
          <!-- <p>违法和不良信息举报电话：0311-86036653</p>
          <p>E-mail：sjzedu@sjy.net.cn</p> -->
          <p>技术支持：师达教育</p>
        </div>
      </div>
    </div>
	<close-and-go-back></close-and-go-back>
  </div>
</template>

<script>
import psyScoreChart from "@/components/psyScoreChart.vue"
import { getEvaResult } from '@/api/student/myquality/shenxin'
import closeAndGoBack from '@/components/closeAndGoBack.vue'
import mediaExhi from "@/components/mediaExhibition.vue"
export default {
  components: {
	  closeAndGoBack,
	  psyScoreChart,
		mediaExhi
  },
  data() {
    return {
      name: '',
      term: '',
      form: {
          questionNaire: [],
      },
      params: {},
      evaluationResult: {},
      dimension: [],
      healthRecord: {},
      healthScore: {},
			evaRes: {},
			topicList: {
				singleAnswer: [],
				multiAnswer: []
			},
			totalScore: {
				singleAnswer: 0,
				multiAnswer: 0
			},
    }
  },
  computed: {
  	tp() {
  		return this.params.evaTypeId
  	},
	tpTxt() {
		let tpMap = {
			'400': '心理知识测评',
			'401': '健康知识测评',
			'402': '艺术知识测评'
		}
		return tpMap[this.tp]
	}
  },
  created() {
		document.documentElement.scrollTop = 0
    this.getParams()
  },
  methods: {
    getParams() {
      this.params = this.$route.params.name != undefined  ? this.$route.params : JSON.parse(sessionStorage['params'])
      this.name = this.params.name
      this.term = this.params.term
      this.form.questionnaireTypeId = this.params.evaluationTypeId
	  
			this.getQuestion()
    },
    getQuestion() {
			getEvaResult({
				id: this.params.id,
				userId: this.params.userId
			}).then(res => {
				let d = res.data
				if (!this.isEmpty(d)) {
					this.evaRes = d
					if (this.tp != 400) {
						// 算总分
						let calcTotalScore = (a, b) => a + (b.topicScore - 0)
						d.submitEva.forEach(v => {
							if (v.topicType == 1) {
								this.topicList.singleAnswer.push(v)
							} else if (v.topicType == 2) {
								v.topicAnswer = v.topicAnswer.split(',').sort().toString()
								v.answer = v.answer.split(',').sort().toString()
								v._answer = v.answer.split(",")
								this.topicList.multiAnswer.push(v)
							}
						})
						this.totalScore.singleAnswer = this.topicList.singleAnswer.reduce(calcTotalScore, 0)
						this.totalScore.multiAnswer = this.topicList.multiAnswer.reduce(calcTotalScore, 0)
					} else {
						d.evaResultNorm.forEach(v1 => {
							v1._scoreModel = []
							d.topicDimensionNorm.forEach(v2 => {
								if (v1.dimensionManagerId == v2.dimensionManagerId) {
									v1._scoreModel.push(v2)
								}
							})
						})
					}
				}
				
			})
    },
    backIndex() {
        this.$router.go(-1)
    }
  },
};
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  min-height: 100vh;
  background: #deedf8;
  .question {
    width: 920px;
    min-height: 500px;
    background: #ffffff;
    border: 1px solid rgba(153, 153, 153, 0.15);
    border-radius: 4px;
    margin: 0 auto;
    padding: 30px 67px 34px 67px;
    box-sizing: border-box;
    .title {
      text-align: center;
      position: relative;
      h2 {
        margin-top: 0;
        font-size: 20px;
        color: #198af7;
      }
      p {
		  text-align: left;
        font-size: 14px;
        color: #656565;
        line-height: 26px;
      }
    }
    .naire {
      .el-form {
        .el-form-item {
          h4 {
            font-size: 14px;
          }
          .el-form-item__label {
            float: none;
          }
          .el-radio-group {
            display: flex;
            flex-direction: column;
            margin-left: 10px;
            .el-radio {
              margin-top: 10px;
            }
          }
          .scontent{
            color: #2ab99c;
          }
        }
      }
      table {
        width: 100%;
        border: 1px solid #ebeef5;
        tr {
          height: 48px;
          th {
            width: 25%;
            font-size: 14px;
            background-color: rgb(246, 247, 250);
            color: rgb(145, 147, 154);
          }
          td{
              font-size: 14px;
              color: #666666;
              text-align: center;
            }
        }
      }
			.options-item {
				display: flex;
				justify-content: flex-start;
				align-items: center;
				.sel {
					flex: 0 0 50px;
					.circle-icon {
						display: flex;
						justify-content: center;
						align-items: center;
						width: 20px;
						height: 20px;
						border: 1px solid #CCC;
						border-radius: 50%;
					}
					.selected {
						border-color: #CCC;
						background-color: #CCC;
						color: #FFF;
					}
				}
				.opt-cont {
					display: flex;
					justify-content: flex-start;
					align-items: center;
					flex: 0 0 calc(100% - 50px);
				}
			}
    }
  }
  .btn-box {
    width: 100%;
    text-align: center;
    margin-top: 28px;
    .el-button {
      margin: 0 25px;
    }
  }
  .footer {
    width: 100%;
    height: 150px;
    position: relative;
    .footer-content {
      max-width: 1400px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      .footer-center {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        p {
          font-size: 14px;
          font-weight: 400;
          line-height: 22px;
          color: #198af7;
          display: inline-block;
          margin: 0 15px;
        }
      }
    }
  }
}
</style>
<style scoped>
.el-form >>> .el-form-item {
    margin-bottom: 0;
}
.right-answer {
	color: #67c23a;
}
</style>
<style lang="scss" scoped>
@import "@/assets/scss/test-page.scss";
</style>